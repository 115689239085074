<!-- 德育管理 > 德育档案 > 宿舍报表 -->
<template>
    <div>
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <!-- 头部筛选 -->
                <div class="filter_disFlex">
                    <div class="filter-wrap">
                        <expand-filter
                            :closeWidth="1070"
                            :formData="formData"
                            @clickBtn="clickBtn"
                            marginBottom="0px"
                            @changeBtnFormType="changeBtnFormType"
                        >
                            <template #datePicker="{slotData}">
                                <div class="date-picker-wrap">
                                    <el-date-picker
                                        v-model="slotData.value"
                                        :clearable="false"
                                        type="datetimerange"
                                        start-placeholder="请选择开始时间"
                                        end-placeholder="请选择结束时间"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        :default-time="['00:00:00', '23:59:59']"
                                    >
                                    </el-date-picker>
                                </div>
                            </template>
                        </expand-filter>
                        <div
                            class="button-line"
                            v-has-permi="[
                                'moralmanage:classmanage:roomdata:list',
                            ]"
                        ></div>
                        <el-button
                            style="margin-left: 0px"
                            :loading="exportLoading"
                            v-has-permi="[
                                'moralmanage:classmanage:roomdata:list',
                            ]"
                            @click="handleExport()"
                            >导出</el-button
                        >
                    </div>
                </div>
                <div
                    ref="content"
                    class="record_content"
                    :style="{
                        height: `${content.height}px`,
                    }"
                    v-loading="loadingTable"
                >
                    <div class="report_top_title_school">
                        <div style="flex: 1"></div>
                        <div class="title">{{ schoolName }}宿舍考核统计表</div>
                        <div style="flex: 1">
                            <div
                                class="disFlex"
                                v-if="sManagerEduMoralReportCheckPerson"
                            >
                                <span>检查人：</span>
                                <span
                                    v-show="!isShow"
                                    v-for="(item, index) in checkUserName
                                        .slice(0, 3)
                                        .join('、')"
                                    :key="index"
                                    >{{ item }}</span
                                >
                                <span
                                    v-show="isShow"
                                    v-for="(item, index) in checkUserName.join(
                                        '、',
                                    )"
                                    :key="index + 'a'"
                                    >{{ item }}</span
                                >
                                <div
                                    style="margin-left: 5px; cursor: pointer"
                                    class="score_img"
                                    v-if="checkUserName.length > 3"
                                >
                                    <img
                                        @click="isShow = true"
                                        v-show="!isShow"
                                        :src="moreUrl"
                                        alt=""
                                        style="width: 14px; height: 14px"
                                    />
                                </div>
                                <div
                                    v-show="isShow"
                                    @click="isShow = false"
                                    class="close_text"
                                >
                                    收起
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="report_top_title">
                        <div class="topOne">
                            <div class="topOne_mes">
                                <div class="time">
                                    {{
                                        nowDate + " " + nowTime + " " + nowWeek
                                    }}
                                </div>
                                <div class="topOne_text">第 - 值班组</div>
                                <div class="topOne_text">
                                    组长：<span style="margin: 0 10px">-</span>
                                    副组长：-
                                </div>
                            </div>
                            <div class="topOne_cord pp">
                                <div>
                                    <img
                                        style="width: 50px; height: 50px"
                                        :src="ppUrl"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div>批评（条）</div>
                                    <div class="num">{{ breakCount || 0 }}</div>
                                </div>
                            </div>
                            <div class="topOne_cord by">
                                <div>
                                    <img
                                        style="width: 50px; height: 50px"
                                        :src="biaoYang"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <div>表扬（条）</div>
                                    <div class="num">{{ honorCount || 0 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="dataList && dataList.length > 0"
                        class="listStyle"
                    >
                        <div
                            class="each_table"
                            v-for="(item, index) in dataList"
                            :key="index"
                        >
                            <div
                                class="top_row"
                                @click="changeBtnTableType(item)"
                            >
                                <div class="disFlex top_row_content">
                                    <div
                                        class="disFlex fontweight"
                                        style="margin-right: 20px"
                                    >
                                        {{ item.roomName }}
                                    </div>
                                    <div class="disFlex">
                                        <div class="score_img">
                                            <img
                                                :src="exportUrl"
                                                alt=""
                                                style="
                                                    width: 14px;
                                                    height: 14px;
                                                "
                                            />
                                        </div>
                                        <div class="all_score">小计：</div>
                                        <div
                                            :class="
                                                item.totalScore < 0
                                                    ? 'add_score'
                                                    : 'reduce_score'
                                            "
                                        >
                                            {{
                                                (item.totalScore < 0
                                                    ? ""
                                                    : "+") + item.totalScore
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="more_img">
                                    <i
                                        class="el-icon-arrow-down"
                                        :class="{
                                            arrowTransform: item.btnFormType1,
                                        }"
                                    ></i>
                                </div>
                            </div>

                            <!-- 列表 -->
                            <table-data
                                ref="table"
                                :config="table_config"
                                :tableData="item.list"
                                v-if="item.btnFormType1"
                            >
                                <template #img="{data}">
                                    <div
                                        class="img-list"
                                        v-if="data.img.length > 0"
                                    >
                                        <div
                                            v-for="(item, index) in data.img"
                                            :key="index"
                                            class="img-item"
                                        >
                                            <video
                                                v-if="
                                                    item
                                                        .toLowerCase()
                                                        .indexOf('mp4') > -1 ||
                                                    item
                                                        .toLowerCase()
                                                        .indexOf('mov') > -1
                                                "
                                                :class="item ? 'img2' : ''"
                                                :src="item"
                                                disablePictureInPicture
                                                @click="handlePreview(item)"
                                            >
                                                <source :src="item" />
                                            </video>
                                            <img
                                                v-else
                                                :src="item"
                                                alt=""
                                                :class="item ? 'img2' : ''"
                                                @click="handleImg(item)"
                                            />
                                        </div>
                                    </div>
                                    <span v-else>-</span>
                                </template>
                            </table-data>
                        </div>
                    </div>
                    <div
                        v-else
                        class="empty-text"
                    >
                        暂无数据
                    </div>
                </div>
            </div>
        </div>
        <!-- 点击查看图片 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="handleCloseImg"
            :url-list="[viewPhoto]"
        />
        <!-- mov视频预览 -->
        <dialog-wrapper
            :dialog-obj="dialogMov"
            @handleClose="handleCloseVideo"
            class="dialog-form-video"
        >
            <video
                v-if="videoMovSrc"
                ref="videoElement"
                autoplay
                style="width: 100%; height: 100%"
                controls
                disablePictureInPicture
            >
                <source :src="videoMovSrc" />
            </video>
        </dialog-wrapper>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    // TableData,
    // 功能组件
    Error,
    Loading,
    DialogWrapper,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import {mapState} from "vuex";
import {downloadFile, formatTreeData} from "@/libs/utils.js";
import ExpandFilter from "./Sub/ExpandFilter.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
// import onResize from "@/mixins/onResize";
import {SManagerEduDeormitoryDataModule} from "@/models/SManagerEduDormitoryAssessmentData.js";

export default {
    name: "SManagerEduDormitoryReport",
    // mixins: [onResize],
    components: {
        ExpandFilter,
        // 功能组件
        Error,
        Loading,
        TableData,
        ElImageViewer,
        DialogWrapper,
    },

    data() {
        return {
            listQuery: {
                schoolId: "",
                ruleType: "",
                dormitoryRoomId: "", // 房间id
                assessDateStart: "",
                assessDateEnd: "",
                dataScope: "",
            },
            total: 0,
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            videoMovSrc: "",
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择类型",
                        key: "ruleType",
                        list: [
                            {
                                label: "表扬",
                                value: "2",
                            },
                            {
                                label: "批评",
                                value: "1",
                            },
                        ],
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "请选择宿舍",
                        key: "dormitoryRoomId",
                        clearable: true,
                        // showAllLevels: false,
                        cascaderProps: {
                            label: "names",
                            value: "id",
                            emitPath: false,
                            multiple: true,
                            // checkStrictly: true,
                        },
                        list: [],
                    },
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["moralmanage:classmanage:roomdata:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["moralmanage:classmanage:roomdata:list"],
                    },
                ],
                btnFormType: true,
            },
            btnFormType1: true,
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "学生",
                        prop: "studentName",
                        align: "center",
                        labelWidth: "100",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "考核行为",
                        prop: "behaviorName",
                        align: "center",
                    },
                    {
                        type: "function",
                        label: "分数",
                        prop: "score",
                        callBack(row) {
                            if (row.ruleType === "2") {
                                return `<div style="color:#71CE66;">+${row.score}</div>`;
                            } else if (row.ruleType === "1") {
                                return `<div style="color:#F99D5E;">-${row.score}</div>`;
                            } else {
                                return "-";
                            }
                        },
                        align: "center",
                        labelWidth: "100",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "凭证",
                        type: "slot",
                        slotName: "img",
                        align: "center",
                        fixed: false,
                        labelWidth: "120",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "备注",
                        prop: "remark",
                        align: "center",
                        className: "text-spacing-reduction",
                    },

                    {
                        label: "考核时间",
                        align: "center",
                        prop: "assessDate",
                    },
                ],
                check: false,
                height: "",
                refreshTable: false,
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            exportLoading: false,
            tableListSel: [],
            // 总计条数
            honorCount: 0, // 表扬
            breakCount: 0, // 批评
            checkUserName: [], // 检查人数组
            isShow: false,
            nowDate: "", // 当前日期
            nowTime: "", // 当前时间
            nowWeek: "", // 当前星期
            content: {
                height: "",
            },
            dataList: [], // 列表数组
            totalScore: 0, // 每个标记的小计
            gradeList: [],
            schoolCalendar: [],
            firstTime: true,
            showViewer: false,
            viewPhoto: "",
            sManagerEduMoralReportCheckPerson: false,
        };
    },
    created() {
        this.init();
    },
    mounted() {
        this.resizeHandler();
        this.currentTime();
    },
    // 销毁定时器
    beforeDestroy() {
        if (this.getDate) {
            clearInterval(this.getDate); // 在Vue实例销毁前，清除时间定时器
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.initBaseData("activated");
        }
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            schoolName: (state) => state.reportSchoolName,
            permissions: (state) => state.permissions,
        }),
        // 下载模板
        downLoadTempBtn() {
            return ["dormitoryDistribution:downLoadTemp"];
        },
        // 统计图标
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
        // 表扬图标
        praiseUrl() {
            return require("@/assets/images/moralAsstessment/honour-icon.png");
        },
        // 批评图标
        criticizeUrl() {
            return require("@/assets/images/moralAsstessment/smile-down-icon.png");
        },
        // 更多图标
        moreUrl() {
            return require("@/assets/images/moralAsstessment/showmore-icon.png");
        },
        // 批评图标
        ppUrl() {
            return require("@/assets/images/piPing.png");
        },
        // 表扬图标
        biaoYang() {
            return require("@/assets/images/biaoYang.png");
        },
    },
    methods: {
        init() {
            this.initAssignment();
            this.initTableConfig();
            this.initBaseData("created");
        },
        initAssignment() {
            this.firstTime = false;
            this.listQuery.schoolId = this.schoolId;
        },
        initTableConfig() {
            this.sManagerEduMoralReportCheckPerson = this.permissions.some(
                (item) => {
                    return (
                        "moralmanage:classmanage:roomdata:checkPerson" == item
                    );
                },
            );
            if (!this.sManagerEduMoralReportCheckPerson) return;

            let tableConfig = {
                    label: "检查人",
                    prop: "createByName",
                    align: "center",
                },
                theadIdx = 0,
                theadBol = false;

            this.table_config.thead.forEach((item, index) => {
                if (item.prop == "remark") {
                    theadIdx = index + 1;
                }

                if (item.prop == "createByName") {
                    theadBol = true;
                }
            });

            if (!theadBol) {
                this.table_config.thead.splice(theadIdx, 0, tableConfig);
            }
            console.log(
                this.formData.data,
                this.table_config.thead,
                "this.formData.data",
            );
        },
        /**
         * @Description: 获取宿舍树
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-26 10:36:02
         */
        getDormitoryList() {
            const sManagerEduDeormitoryDataModule =
                new SManagerEduDeormitoryDataModule();
            sManagerEduDeormitoryDataModule
                .getAllDormitoryRoomList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code === "200") {
                        const listdata = res.data.data;
                        console.log("宿舍树", res.data.data);
                        this.formData.data.forEach((item) => {
                            if (item.key == "dormitoryRoomId") {
                                item.list = listdata;
                                console.log("item.list", item.list);
                            }
                        });
                    } else {
                        this.$message.error("数据请求失败，请重试");
                    }
                });
        },
        /**
         * @Description: 点击图片放大
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 16:29:59
         * @param {*} data
         */
        handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        handleCloseImg() {
            this.showViewer = false;
        },
        handleCloseVideo() {
            this.$refs.videoElement.pause();
            this.dialogMov.dialogVisible = false;
            this.videoMovSrc = "";
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
        handlePreview(data) {
            console.log("预览视频", data);
            if (data.toLowerCase().indexOf("mov") > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = [
                    "swf",
                    "avi",
                    "flv",
                    "mpg",
                    "rm",
                    "mov",
                    "wav",
                    "asf",
                    "3gp",
                    "mkv",
                    "rmvb",
                    "mp4",
                ];
                let url = data.split("/");
                url = url[url.length - 1].split(".");
                url = url[url.length - 1];

                if (
                    data.indexOf("minio") !== -1 &&
                    videoType.indexOf(url) == -1
                ) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data);
                } else {
                    let url = decodeURIComponent(data); // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64;
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(
                        Base64.encode(url),
                    )}`;
                    window.open(previewUrl);
                }
            }
        },
        async initBaseData(key) {
            await this.getSchoolCalendar();
            await this.getDormitoryList();
            if (key === "created") {
                this.setDefaultDate();
            }
            this.getList();
        },
        /**
         * @Description: 查询校历
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-06 16:19:48
         */
        async getSchoolCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
            };
            await this._fet(
                "/school/schoolCalendar/listByCondition",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data;
                }
            });
        },
        /**
         * @Description: 设置默认查询时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 09:54:55
         */
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD HH:mm");
            this.listQuery.assessDateEnd =
                this.$moment().format("YYYY-MM-DD HH:mm");
            if (this.schoolCalendar.length > 0) {
                // 根据校历设置默认查询时间
                let {startTime, endTime} = this.schoolCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.listQuery.assessDateEnd < endTime &&
                    this.listQuery.assessDateEnd > startTime
                ) {
                    this.listQuery.assessDateStart = startTime.concat(" 00:00");
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        /**
         * @Description: 获取班级考核列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:03:25
         */
        getList() {
            this.dataList = [];
            // 检查人清空
            this.checkUserName = [];
            this.loadingTable = true;
            const sManagerEduDeormitoryDataModule =
                new SManagerEduDeormitoryDataModule();
            sManagerEduDeormitoryDataModule
                .getDormitoryMoralReport(this.listQuery)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.breakCount = res.data.data.breakCount;
                        this.honorCount = res.data.data.honorCount;
                        if (res.data.data.checkUserName) {
                            this.checkUserName =
                                // res.data.data.checkUserName.split(",");
                                res.data.data.checkUserName;
                        }
                        if (this.checkUserName.length > 5) {
                            this.isShow = false;
                        }
                        res.data.data.dataList.forEach((i) => {
                            i.btnFormType1 = true;
                            i.list.forEach((iv) => {
                                if (iv.img) {
                                    iv.img = iv.img.split(",");
                                } else {
                                    iv.img = [];
                                }
                            });
                        });
                        this.dataList = res.data.data.dataList;

                        this.dataList.forEach((j, index) => {
                            if (index == 0) {
                                j.btnFormType1 = true;
                            } else {
                                j.btnFormType1 = false;
                            }
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 点击查询/重置按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:57
         * @param {*} data
         */
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        } else if (item.key == "dormitoryRoomId") {
                            let roomArr = item.value.toString();
                            this.listQuery.dormitoryRoomId = roomArr;
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    });
                    this.getList();
                    break;
                case "reset": // 重置
                    this.checkUserName = [];
                    this.dataList = [];
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.setDefaultDate();
                    this.getList();
                    break;
                default:
                    break;
            }
        },
        recursion(children, itemm) {
            let self = this;
            if (children) {
                children.forEach((item) => {
                    if (item.child && item.child.length > 0) {
                        self.recursion(item.child, item);
                    }
                });
            }
        },
        // 条件筛选的 展开 / 收起
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.resizeHandler();
        },

        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:55:29
         */
        handleExport() {
            this.exportLoading = true;
            let form = this.listQuery;

            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/getRoomMoralReportExport",
                    form,
                },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },

        /**
         * @Description: 动态调整表格高度
         * @DoWhat: 初始化表格高度
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 10:50:04
         */
        resizeHandler() {
            this.$nextTick(() => {
                this.content.height = document.body.clientHeight - 235;
            });
        },
        /**
         * @Description: 获取当前时间
         * @DoWhat: 年月日时分秒星期
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:00:24
         */
        currentTime() {
            setInterval(this.getDate, 500);
        },
        /**
         * @Description: 获取时间戳
         * @DoWhat: 年月日时分秒星期
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-31 15:01:52
         */
        getDate() {
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let week = new Date().getDay();
            let hh = new Date().getHours();
            let ms =
                new Date().getSeconds() < 10
                    ? "0" + new Date().getSeconds()
                    : new Date().getSeconds();
            let mf =
                new Date().getMinutes() < 10
                    ? "0" + new Date().getMinutes()
                    : new Date().getMinutes();
            if (week == 1) {
                this.nowWeek = "星期一";
            } else if (week == 2) {
                this.nowWeek = "星期二";
            } else if (week == 3) {
                this.nowWeek = "星期三";
            } else if (week == 4) {
                this.nowWeek = "星期四";
            } else if (week == 5) {
                this.nowWeek = "星期五";
            } else if (week == 6) {
                this.nowWeek = "星期六";
            } else {
                this.nowWeek = "星期日";
            }
            _this.nowTime = hh + ":" + mf + ":" + ms;
            _this.nowDate = yy + "-" + mm + "-" + dd;
        },
        /**
         * @Description: able展开/折叠操作
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-07 17:00:23
         */
        changeBtnTableType(item) {
            item.btnFormType1 = !item.btnFormType1;
        },
    },
};
</script>

<style scoped lang="scss">
.content-main {
    position: relative;
    overflow: hidden;
    // background-color: #fff;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 165px);
}

.filter_disFlex {
    // border-bottom: 1px solid #edf0f2;
    margin-bottom: 10px;
}
.filter_lr {
    display: flex;
    // justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}
.filter_left {
    padding-left: 0;
    // ::v-deep .button,
    // ::v-deep .el-button {
    //     height: 36px;
    //     border-radius: 4px;
    //     padding: 9px 18px;
    // }
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    // margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.upload-demo {
    display: inline-block;
}

.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}

.el-button {
    font-size: 14px;
    margin-left: 10px;
}

.download-btn {
    border: 0;
    background-color: #3dc5f2;

    &:hover {
        background-color: #5ecff5;
    }
}

.drawerExam-container {
    ::v-deep .v-modal {
        position: absolute;
    }
}
.record_content {
    background-color: #fff;
    overflow: auto; // 超出这个最大高度的数据，会被隐藏起来，上下滑动
    overflow-x: hidden; //隐藏x轴滚动条
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
    border-radius: 4px;
    margin: 10px 0px 0px 10px;
}

.report_top_title_school {
    width: 100%;
    margin: 20px 0px 15px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .title {
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        margin-right: 50px;
    }
    .subName {
        font-weight: 400;
        font-size: 14px;
        color: #2b2f33;
    }

    .checkUserNameList {
        .checkUserNameList-name {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.listStyle {
    width: 90%;
}

.report_top_title {
    text-align: center;
    margin: 15px  auto;
    width: 90%;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .topOne {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        padding: 10px 200px 10px 0px;
        .topOne_mes {
            flex-shrink: 0;
            width: fit-content;
            margin-right: 50px;
            text-align: left;
            & > div:nth-of-type(2) {
                margin: 3px 0px;
            }
        }
        .time {
            font-weight: bold;
            font-size: 14px;
            color: #2b2f33;
        }
        .topOne_text {
            font-weight: 400;
            font-size: 14px;
            color: #2b2f33;
        }

        .topOne_cord {
            overflow: hidden;
            margin: 0px 20px;
            width: 190px;
            height: 63px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            & > div:nth-of-type(1) {
                flex-shrink: 0;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
            & > div:nth-of-type(2) {
                flex: 1;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                color: #2b2f33;
                .num {
                    font-weight: bold;
                    font-size: 20px;
                    color: #2b2f33;
                }
            }
        }
        .pp {
            background: #ffeded;
            border: 1px solid #ff7878;
        }
        .pp:hover {
            background: rgba($color: #ffeded, $alpha: 0.5);
        }
        .by {
            background: #f2ffed;
            border: 1px solid #29b41a;
        }
        .by:hover {
            background: rgba($color: #f2ffed, $alpha: 0.5);
        }
        .jc {
            background: #feedff;
            border: 1px solid #da41dc;
        }
        .jc:hover {
            background: rgba($color: #feedff, $alpha: 0.5);
        }
    }

    .report_top_title_school {
        // width: 218px;
        height: 20px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 18px;
    }

    .report_top_title_alltotal {
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .report_top_member {
        display: flex;
        font-size: 14px;
        margin: 0 auto;
        margin-bottom: 15px;
        width: 90%;
        align-items: center;

        .report_current_time {
            margin-right: 10px;
        }

        .report_rightLine {
            width: 1px;
            height: 16px;
            font-weight: bold;
            background: #e1e3e6;
            margin: 0 20px;
        }
    }
}

.disFlex {
    display: flex;
    align-items: center;
}
.close_text {
    color: #66bfff;
    cursor: pointer;
}

.score_img {
    margin-right: 5px;
    line-height: 10px;
}

::v-deep .el-table {
    border: 1px solid #ebeef5;
}
.each_table {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 auto;
    padding: 5px 20px;
    margin-bottom: 10px;
    .top_row {
        display: flex;
        font-size: 14px;
        line-height: 40px;
        justify-content: space-between;
        transition: all 0.4s;
        padding-left: 30px;

        .top_row_content {
            flex: 1;
            padding-right: 20px;
        }
        .disFlex {
            display: flex;
        }
        .fontweight {
            font-weight: bold;
        }
        .score_img {
            margin-top: 2px;
        }
        .more_img {
            margin-top: 2px;
            cursor: pointer;
        }
        .el-icon-arrow-down {
            transition: 0.2s;
            line-height: 40px;
            color: #d4d7d9;
            font-weight: bold;
        }
        .arrowTransform {
            transform: rotateZ(180deg);
        }

        .all_score {
            margin-left: 7px;
        }

        .add_score {
            color: #f99d5e;
            margin-left: 4px;
        }

        .reduce_score {
            color: #71ce66;
            margin-left: 4px;
        }
    }
    // .top_row:hover {
    //     cursor: pointer;
    //     box-shadow: 3px 3px 10px 3px rgba(80, 79, 79, 0.1);
    //     transform: scale(1.01);
    // }
}
.img-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-item {
        margin: 2px 0;
        flex-shrink: 0;
        background-color: #333;
        width: 50px;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.img2 {
    // width: 60px;
    // width: 100%;
    max-width: 100%;
    max-height: 100%;
}
.empty-text {
    text-align: center;
    padding: 50px;
    color: #909399;
}
::v-deep .el-table .el-table__row {
    border: none;
}
::v-deep .el-table {
    border: none;
}
.dialog-form-video {
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px !important;
    }
}
</style>
